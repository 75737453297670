.hamburger-overlay {
  position: absolute;
  left: 0rem;
  right: 0rem;
  top: 3.5rem;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
}

.hamburger {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: .5rem;
}