@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #e58a07;
  --gray-darker: #313030;
  --gray-dark: #686565;
  --gray: #80807C;
  --gray-light: #D3CBC6;
}

html {
  height: 100%;
}

body {
  background-color: var(--gray);
  min-height: 100%;
}

html, body, #root {
  height: 100%;
  position: relative;
}
